
import HeaderComponent from "./components/Header.vue";
import FooterComponent from "./components/Footer.vue";
import Maintenance from "@hd2/common/src/views/Maintenance.vue";
import { defineComponent, computed, inject } from "vue";
import { useI18n } from "vue-i18n";
import plPL from "ant-design-vue/es/locale/pl_PL";
import dayjs from "dayjs";
import { useRouter } from "vue-router";
import { useStore } from "./store";
import { AxiosStatic } from "axios";
import { useKeycloak } from "/src/composable/useKeycloak";
import Loading from "@hd2/common/src/views/Loading.vue";
import { appTypeClassList } from "/src/utils/const";

dayjs.locale("pl");

export const AppComponent = defineComponent({
  components: {
    Maintenance,
    "app-header": HeaderComponent,
    "app-footer": FooterComponent,
    Loading,
  },
  setup() {
    const { createKeycloak, logoutUser } = useKeycloak();
    const store = useStore();
    const { t } = useI18n();
    const router = useRouter();
    const http = inject("http") as AxiosStatic;
    const kcAuthUrl = inject("kcAuthUrl");
    const authRealm = inject("kcAuthRealm");
    const authClientId = inject("kcAuthClientId");
    const appType = inject("appType");

    const appTypeClass = () => {
      const appName =
        appType && typeof appType === "string" ? appType.toString() : "";
      if (appName.length > 0) {
        const className = (
          appTypeClassList as unknown as Map<string, string>
        ).get(appName);
        if (className) document.querySelector("body")?.classList.add(className);
        return className;
      } else {
        return "";
      }
    };

    const removeCookies = () => {
      const allCookies = document.cookie.split(";");

      for (let i = 0; i < allCookies.length; i++) {
        const cookie = allCookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

        if (
          name.includes("idToken") ||
          name.includes("refreshToken") ||
          name.includes("userData")
        ) {
          document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.welbi.pl`;
        }
      }
    };

    if (appType === "WELBI") {
      removeCookies();
    }

    createKeycloak(
      {
        url: kcAuthUrl + "/auth",
        realm: authRealm as string,
        clientId: authClientId as string,
      },
      http,
      t,
      store
    );

    return {
      maintenance: computed(() => store.state.runtimeConfig.maintenance),
      t,
      plPL,
      routerPath: computed(() => router.currentRoute.value.name),
      loading: computed(() => store.getters.getLoadingStatus),
      appTypeClass,
      logout: logoutUser,
    };
  },
});
export default AppComponent;
