import { ResponseList, Visit } from "../../../types";
import dayjs from "dayjs";

const finishedAt = (date: string) => {
  const d = new Date(date);
  return new Date(d.setHours(d.getHours() + 1)).toString();
};

const conversationExpiresAt = (date: string) => {
  const d = new Date(date);
  return new Date(d.setDate(d.getDate() + 1)).toString();
};

const visits: ResponseList<Visit> = {
  content: [
    {
      id: "129673223",
      appointmentAt: dayjs().add(5, "minutes").format(),
      doctor: "Jan Kowalski",
      finishedAt: finishedAt("2020-10-06T12:49:47.955Z"),
      conversationExpiresAt: conversationExpiresAt("2020-10-06T12:49:47.955Z"),
      contractorId: "7",
      processStatus: "PENDING",
      aureroVisitUrl: "https://homedoctor.pl/",
      visitType: "VIDEO",
      nfzVisit: false,
      specialization: "ALLERGIST",
    },
    {
      id: "129623213",
      appointmentAt: dayjs().add(12, "minutes").format(),
      finishedAt: finishedAt("2020-10-06T12:49:47.955Z"),
      conversationExpiresAt: conversationExpiresAt("2020-10-06T12:49:47.955Z"),
      doctor: "Jan Kowalski",
      contractorId: "7",
      processStatus: "PENDING",
      aureroVisitUrl: "https://homedoctor.pl/",
      visitType: "CHAT",
      nfzVisit: false,
      specialization: "INTERNIST",
    },
    {
      id: "12968223",
      appointmentAt: "2020-10-06T10:49:47.955Z",
      finishedAt: finishedAt("2020-10-06T10:49:47.955Z"),
      conversationExpiresAt: conversationExpiresAt("2020-10-06T10:49:47.955Z"),
      doctor: "Jan Kowalski",
      contractorId: "7",
      processStatus: "PAID",
      visitType: "STATIONARY",
      nfzVisit: true,
      specialization: "INTERNIST",
    },
    {
      id: "122378223",
      appointmentAt: "2020-10-04T10:49:47.955Z",
      finishedAt: finishedAt("2020-10-06T12:49:47.955Z"),
      conversationExpiresAt: conversationExpiresAt("2020-10-06T12:49:47.955Z"),
      doctor: "Jan Kowalski",
      contractorId: "7",
      processStatus: "FINISHED",
      visitType: "HOUSE",
      nfzVisit: false,
      specialization: "INTERNIST",
    },
    {
      id: "12853423",
      appointmentAt: "2020-10-01T10:49:47.955Z",
      finishedAt: finishedAt("2020-10-01T10:49:47.955Z"),
      conversationExpiresAt: conversationExpiresAt("2020-10-01T10:49:47.955Z"),
      doctor: "Jan Kowalski",
      contractorId: "7",
      processStatus: "FINISHED",
      visitType: "CHAT",
      nfzVisit: false,
      specialization: "CARDIOLOGIST",
    },
    {
      id: "1745899223",
      appointmentAt: "2020-10-02T10:49:47.955Z",
      finishedAt: finishedAt("2020-10-02T10:49:47.955Z"),
      conversationExpiresAt: conversationExpiresAt("2020-10-02T10:49:47.955Z"),
      doctor: "Jan Kowalski",
      contractorId: "7",
      processStatus: "FINISHED",
      visitType: "CALL",
      nfzVisit: true,
      specialization: "UROLOGIST",
    },
    {
      id: "123456333",
      appointmentAt: "2020-09-28T10:49:47.955Z",
      finishedAt: finishedAt("2020-10-06T12:49:47.955Z"),
      conversationExpiresAt: conversationExpiresAt("2020-10-06T12:49:47.955Z"),
      doctor: "Jan Kowalski",
      contractorId: "7",
      processStatus: "FINISHED",
      visitType: "STATIONARY",
      nfzVisit: false,
      specialization: "ALLERGIST",
    },
    {
      id: "17887523",
      appointmentAt: "2020-09-29T10:49:47.955Z",
      finishedAt: finishedAt("2020-09-29T10:49:47.955Z"),
      conversationExpiresAt: conversationExpiresAt("2020-09-29T10:49:47.955Z"),
      doctor: "Jan Kowalski",
      contractorId: "7",
      processStatus: "FINISHED",
      visitType: "CHAT",
      nfzVisit: false,
      specialization: "PSYCHIATRIST",
    },
    {
      id: "1234767823",
      appointmentAt: "2020-09-11T10:29:47.955Z",
      finishedAt: finishedAt("2020-09-11T10:29:47.955Z"),
      conversationExpiresAt: conversationExpiresAt("2020-09-11T10:29:47.955Z"),
      doctor: "Jan Kowalski",
      contractorId: "7",
      processStatus: "FINISHED",
      visitType: "CHAT",
      nfzVisit: false,
      specialization: "PEDIATRICIAN",
    },
    {
      id: "17622436543",
      appointmentAt: "2020-09-23T16:49:47.955Z",
      finishedAt: finishedAt("2020-09-23T16:49:47.955Z"),
      conversationExpiresAt: conversationExpiresAt("2020-09-23T16:49:47.955Z"),
      doctor: "Jan Kowalski",
      contractorId: "7",
      processStatus: "FINISHED",
      visitType: "CHAT",
      nfzVisit: false,
      specialization: "PEDIATRICIAN",
    },
    {
      id: "89236542",
      appointmentAt: "2020-09-16T12:49:47.955Z",
      finishedAt: finishedAt("2020-09-16T12:49:47.955Z"),
      conversationExpiresAt: conversationExpiresAt("2020-09-16T12:49:47.955Z"),
      doctor: "Jan Kowalski",
      contractorId: "7",
      processStatus: "FINISHED",
      visitType: "CHAT",
      nfzVisit: false,
      specialization: "PEDIATRICIAN",
    },
    {
      id: "111118223",
      appointmentAt: "2020-09-23T10:49:47.955Z",
      finishedAt: finishedAt("2020-09-23T10:49:47.955Z"),
      conversationExpiresAt: conversationExpiresAt("2020-09-23T10:49:47.955Z"),
      doctor: "Jan Kowalski",
      contractorId: "7",
      processStatus: "PAID",
      visitType: "CHAT",
      nfzVisit: false,
      specialization: "PEDIATRICIAN",
    },
  ],
  totalElements: 12,
  size: 10,
  pageable: {
    pageNumber: 0,
  },
};

export default visits;
