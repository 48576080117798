import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-86c9555e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "maintenance" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_result = _resolveComponent("a-result")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_result, {
      status: "500",
      title: _ctx.title
    }, {
      subTitle: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString(_ctx.description), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title"])
  ]))
}