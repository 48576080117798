
import { defineComponent } from "vue";

export const MaintenanceComponent = defineComponent({
  props: {
    title: {
      type: String,
      default: "Technical break",
    },
    description: {
      type: String,
      default:
        "We're sorry, but we're having some technical difficulties. Please try again later.",
    },
  },
  setup() {
    return {};
  },
});

export default MaintenanceComponent;
